/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
@import "~roboto-fontface/css/roboto/roboto-fontface.css";
@import "~ngx-toastr/toastr.css";

body {
  font-family: Roboto, sans-serif;
  //font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
}

// bootstrap overrides

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  margin-left: -35px;
  margin-right: -35px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

h5, .h5 {
  font-size: 15px;
  color: #1d3c83;
  font-weight: 800;
  margin-bottom: 20px;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: #777;
  font-weight: 600;
}

.container {
  margin-top: 30px;
  margin-bottom: 30px;
}

//own styles

.joyrideimage {
  height: 300px;
  width: 307px;
}

.btn-primary {
  background: #fbba00;
  padding: 5px 12px;
  border-radius: 20rem;
  border: 0;
}

.sidebar-background {
  overflow: hidden;
  height: 350px;
  width: 100%;
  background: url("assets/img/sidebarbg.png") no-repeat top;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}

.fatfont {
  font-weight: 800;
}

.logotext {
  color: #1d3c83;
  margin-top: -30px;
  padding-bottom: 80px;
  font-size: 23px;
}

.bluecard {
  background-color: #1d3c83;
  color: #fff;
}

.btn-blue {
  background: linear-gradient(60deg, #0087f7, #0071cd);
}

// modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 98%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 0;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.52);
}

// Map Styles

.map {
  height: 100vh;
}

.ol-overlaycontainer-stopevent {
  position: fixed;
  display: none;
}

.ol-box {
  box-sizing: border-box;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 4px solid #007be0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

// Boostrap Buttons

.btn:active, .btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-light {
  border-color: #c7c7c7;
}

.btn-superlight {
  color: #1d3c83;
  background: none;
  border: 0 solid;
}
// Boostrap Cards

.card {
  padding: 8px;
  border-radius: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.card-body[_ngcontent-c18] {
  border: 0;
  border-radius: 0;
}

.card-header {
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  background: #fbba00;
  border-radius: 20rem;
  margin-top: -20px;
  text-align: center;
  border-bottom: 0;
}


.card-header h5 {
  color: #fff !important;
  margin-bottom: 0 !important;
  font-weight: 300 !important;
}

.card-header h5 i {
  padding-right: 1rem;
}

// Component restyling

.color-picker {
  margin-left: -160px;
  border: 0 !important;
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.3);
}

// INPUTS

/* form starting stylings ------------------------------- */


/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: calc(100% - 16px);
}

.bar::before, .bar::after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
}

.bar::before {
  left: 50%;
}

.bar::after {
  right: 50%;
}

/* active state */
input:focus ~ .bar::before, input:focus ~ .bar::after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

// Toggle Buttons
.group {
  position: relative;
  margin-bottom: 30px;
}

.group input {
  font-size: 13px;
  padding: 10px 10px 10px 5px;
  display: block;
  //width:300px;
  border: none;
  border-bottom: 1px solid #dee2e6;
}

input:focus {
  outline: none;
}

.group label {
  pointer-events: none;
  color: #999;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
}

$switch-height: calc(#{$input-height} * 0.8) !default;
$switch-height-sm: calc(#{$input-height-sm} * 0.8) !default;
$switch-height-lg: calc(#{$input-height-lg} * 0.8) !default;
$switch-border-radius: $switch-height !default;
$switch-bg: $custom-control-indicator-bg !default;
$switch-checked-bg: map-get($theme-colors, "primary") !default;
$switch-disabled-bg: $custom-control-indicator-disabled-bg !default;
$switch-disabled-color: #ccc !default;
$switch-thumb-bg: $white !default;
$switch-thumb-border-radius: 50% !default;
$switch-thumb-padding: 2px !default;
$switch-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba(map-get($theme-colors, "primary"), 0.25);
$switch-transition: 0.2s all !default;

.switch {
  font-size: $font-size-base;
  position: relative;

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;

    + label::before, + label::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(#{$switch-height} * 2);
      bottom: 0;
      display: block;
    }

    + label::before {
      right: 0;
      background-color: $switch-bg;
      border-radius: $switch-border-radius;
      transition: $switch-transition;
    }

    + label {
      position: relative;
      min-width: calc(#{$switch-height} * 2);
      border-radius: $switch-border-radius;
      height: $switch-height;
      line-height: $switch-height;
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      text-indent: calc(calc(#{$switch-height} * 2) + 0.5rem);
    }

    + label::after {
      top: $switch-thumb-padding;
      left: $switch-thumb-padding;
      width: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      height: calc(#{$switch-height} - calc(#{$switch-thumb-padding} * 2));
      border-radius: $switch-thumb-border-radius;
      background-color: $switch-thumb-bg;
      transition: $switch-transition;
    }

    &:checked + label::before {
      background-color: $switch-checked-bg;
    }

    &:checked + label::after {
      margin-left: $switch-height;
    }

    &:focus + label::before {
      outline: none;
      box-shadow: $switch-focus-box-shadow;
    }

    &:disabled + label {
      color: $switch-disabled-color;
      cursor: not-allowed;
    }

    &:disabled + label::before {
      background-color: $switch-disabled-bg;
    }
  }

  // Small variation
  &.switch-sm {
    font-size: $font-size-sm;

    input {
      + label {
        min-width: calc(#{$switch-height-sm} * 2);
        height: $switch-height-sm;
        line-height: $switch-height-sm;
        text-indent: calc(calc(#{$switch-height-sm} * 2) + 0.5rem);
      }

      + label::before {
        width: calc(#{$switch-height-sm} * 2);
      }

      + label::after {
        width: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
        height: calc(#{$switch-height-sm} - calc(#{$switch-thumb-padding} * 2));
      }

      &:checked + label::after {
        margin-left: $switch-height-sm;
      }
    }
  }

  //// Large variation
  //&.switch-lg {
  //  font-size: $font-size-lg;
  //
  //  input {
  //    + label {
  //      min-width: calc(#{$switch-height-lg} * 2);
  //      height: $switch-height-lg;
  //      line-height: $switch-height-lg;
  //      text-indent: calc(calc(#{$switch-height-lg} * 2) + 0.5rem);
  //    }
  //
  //    + label::before {
  //      width: calc(#{$switch-height-lg} * 2);
  //    }
  //
  //    &:checked + label::after {
  //      margin-left: $switch-height-lg;
  //    }
  //
  //    + label::after {
  //      width: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
  //      height: calc(#{$switch-height-lg} - calc(#{$switch-thumb-padding} * 2));
  //    }
  //  }
  //}

  + .switch {
    margin-left: 1rem;
  }
}

/* active state */
.group input:focus ~ label, .group input:valid ~ label {
  top: -20px;
  font-size: 13px;
  color: #5264ae;
}

.ps__rail-y, .ps__rail-x {
  z-index: 100;
}
